import React, { useCallback } from 'react';
import { Pie } from 'react-chartjs-2';

import { ChartData } from 'chart.js';

import { Container, Title, Content, Legend } from './styles';

interface PropsChart {
  title?: string;
  values: number[];
  labels: string[] | number[];
  dataLabels: string[] | number[];
}

interface Props {
  data: PropsChart;
  selectedValue?: string;
  width?: number;
  height?: number;
  colors?: string[];
  selectChart(value: string | number): void;
}

const PieChart: React.FC<Props> = ({
  data,
  selectedValue,
  height,
  width,
  selectChart,
  colors,
}) => {
  const { title, values, labels, dataLabels } = data;

  const dataChart: ChartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors || [
          '#F56565',
          '#f5c365',
          '#4299E1',
          '#42e1b1',
          '#42e157',
        ],
        hoverBackgroundColor: colors || [
          '#F56565',
          '#f5c365',
          '#4299E1',
          '#42e1b1',
          '#42e157',
        ],
      },
    ],
  };

  const handleElementChart = useCallback(
    (dataset) => {
      if (dataset[0]?._view) {
        const index = dataset[0]?._index;
        const value = dataLabels[index];
        selectChart(value);
      }
    },
    [dataLabels, selectChart],
  );
  return (
    <Container>
      {title && <Title>{title}</Title>}

      <Content>
        <Pie
          height={height || 100}
          width={width || 100}
          data={dataChart}
          getElementsAtEvent={handleElementChart}
          options={{
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 15,
                fontSize: 15,
              },
            },
          }}
        />
      </Content>

      {selectedValue && (
        <Legend>
          <strong>{selectedValue}</strong>
          <small>selecionado</small>
        </Legend>
      )}
    </Container>
  );
};

export default PieChart;
