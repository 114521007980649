import React from 'react';
import { FaBuilding, FaIdBadge, FaFileSignature } from 'react-icons/fa';
import {
  FiCalendar,
  FiPieChart,
  FiUsers,
  FiTarget,
  FiShield,
  FiLock,
  FiTrendingUp,
  FiGrid,
  FiBarChart2,
  FiFolder,
  FiMinus,
  FiPlus,
  FiMail,
  FiList,
  FiFileText,
  FiBox,
  FiCornerUpRight,
  FiCornerDownLeft,
  FiSettings,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Text,
  Badge,
} from '@chakra-ui/react';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import { NoteNotification } from '~/modules/dashboard/@types/notification';

interface Props {
  notifications: NoteNotification[];
}

const Menu: React.FC<Props> = ({ notifications }) => {
  /* const notifications: Notification[] = useMemo(() => {
    console.log(localStorage.getItem('@MyIPAC:notification'));
    const notificationStr = localStorage.getItem('@MyIPAC:notification');

    return notificationStr ? JSON.parse(notificationStr) : [];
  }, []); */
  // console.log(notifications?.);
  const { user } = useAuth();
  const can = useCan();
  return (
    <Box bg="gray.50" overflowY="auto" pb={[14, 2]} h="550px">
      <Accordion defaultIndex={[0, 1, 2, 3, 4]} allowMultiple color="gray.500">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                  Painel de controle
                </Box>
                {isExpanded ? <FiMinus size="12px" /> : <FiPlus size="12px" />}
              </AccordionButton>
              <AccordionPanel pb={4}>
                {can('dashboard') && (
                  <Link to="/dashboard">
                    <Flex alignItems="center" py={2}>
                      <FiPieChart size={22} />
                      <Text fontSize="md" fontWeight="medium" ml={2}>
                        Painel de controle
                      </Text>
                    </Flex>
                  </Link>
                )}
                {can('list-report') && (
                  <Accordion allowMultiple>
                    <AccordionItem border={0}>
                      <AccordionButton pl={0}>
                        <Box
                          display="flex"
                          textAlign="left"
                          flexDir="row"
                          alignItems="center"
                        >
                          <FiBarChart2 size={22} />
                          <Text fontSize="md" fontWeight="medium" ml={2}>
                            Relatórios
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {can('list-report-activities') && (
                          <Link to="/relatorios/atividades">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Atividades
                            </Text>
                          </Link>
                        )}
                        {can('list-activities-execution-report') && (
                          <Link to="/relatorios/atividades/execucao">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Execução de Atividades
                            </Text>
                          </Link>
                        )}

                        {can('list-demands') && (
                          <Link to="/relatorios/analise-de-demandas">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Situação de Demandas
                            </Text>
                          </Link>
                        )}

                        {can('list-weekly-demands') && (
                          <Link to="/relatorios/demandas-semanais">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Demandas Semanais
                            </Text>
                          </Link>
                        )}
                        {can('list-monthly-demands') && (
                          <Link to="/relatorios/demandas-mensais">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Demandas Mensais
                            </Text>
                          </Link>
                        )}

                        {can('list-individual-performance') && (
                          <Link to="/relatorios/desempenho-individual">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Desempenho Individual
                            </Text>
                          </Link>
                        )}

                        {can('show-structure-consumption') && (
                          <Link to="/relatorios/consumo-estrutura">
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              ml={1}
                              pl={2}
                              mt={2}
                              borderLeftWidth={1}
                              borderColor="gray.300"
                            >
                              Consumo de Estrutura
                            </Text>
                          </Link>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {can('list-bsc') && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    Gerenciamento
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <FiPlus size="12px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {can('list-bsc') && (
                    <Link to="/bsc">
                      <Flex alignItems="center" py={2}>
                        <FiBarChart2 size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Balanced Scorecard
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  <Link to="/gerenciar/departamentos">
                    <Flex alignItems="center" py={2}>
                      <FiGrid size={22} />
                      <Text fontSize="md" fontWeight="medium" ml={2}>
                        Departamentos
                      </Text>
                    </Flex>
                  </Link>
                  {can('list-attendances') && (
                    <Link to="/gerenciar/atendimentos">
                      <Flex alignItems="center" py={2}>
                        <FaIdBadge size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Atendimentos
                        </Text>
                      </Flex>
                    </Link>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
        {can('list-demands') && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    Demandas / Atividades
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <FiPlus size="12px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/demandas">
                    <Flex alignItems="center" py={2}>
                      <FiList size={22} />
                      <Text fontSize="md" fontWeight="medium" ml={2}>
                        Demandas
                      </Text>
                    </Flex>
                  </Link>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}

        {can('list-estructure-default') && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    Documentos
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <FiPlus size="12px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Link to="/documentos/estrutura-padrao">
                    <Flex alignItems="center" py={2}>
                      <FiFolder size={22} />
                      <Text fontSize="md" fontWeight="medium" ml={2}>
                        Estrutura Padrão
                      </Text>
                    </Flex>
                  </Link>
                  <Link to="/arquivos">
                    <Flex alignItems="center" py={2}>
                      <FiFolder size={22} />
                      <Text fontSize="md" fontWeight="medium" ml={2}>
                        Arquivos
                      </Text>
                    </Flex>
                  </Link>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}

        {can('execute-activity') && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    Planejamento
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <FiPlus size="12px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {can('execute-activity') && (
                    <Link to="/planejamento">
                      <Flex alignItems="center" py={2}>
                        <FiCalendar size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Ver Atividades
                        </Text>
                      </Flex>
                    </Link>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
        {can('list-performance-reviews') && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    GDP
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <Flex alignItems="center">
                      {notifications && !!notifications.length && (
                        <Badge
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          rounded="50%"
                          variant="solid"
                          colorScheme="red"
                          height="18px"
                          width="18px"
                          right={0}
                          top={0}
                          mr={3}
                        >
                          {notifications?.length}
                        </Badge>
                      )}
                      <FiPlus size="12px" />
                    </Flex>
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {can('setting-review') && (
                    <Link to="/configuracao-avaliacao">
                      <Flex alignItems="center" py={2}>
                        <FiSettings size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Configurar Avaliações
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-bsc') && (
                    <Link to="/perfil">
                      <Flex alignItems="center" py={2}>
                        <FiTrendingUp size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Desempenho / BSC
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('create-performance-reviews') && (
                    <Link to="/relatorios/avaliacao-desempenho">
                      <Flex
                        alignItems="center"
                        py={2}
                        justifyContent="space-between"
                      >
                        <Flex>
                          <Flex alignItems="center">
                            <FiFileText size={22} />
                            <Box as="span" marginLeft="-2px">
                              <FiCornerUpRight size={14} />
                            </Box>
                          </Flex>
                          <Text fontSize="md" fontWeight="medium" ml={2}>
                            Avaliações Realizadas
                          </Text>
                        </Flex>
                      </Flex>
                    </Link>
                  )}
                  <Link to="/relatorios/avaliacao-desempenho?recebidas=1">
                    <Flex
                      alignItems="center"
                      py={2}
                      justifyContent="space-between"
                      position="relative"
                    >
                      <Flex>
                        <Flex alignItems="center">
                          <FiFileText size={22} />
                          <Box as="span" marginLeft="-2px">
                            <FiCornerDownLeft size={14} />
                          </Box>
                        </Flex>
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Avaliações Recebidas
                        </Text>
                      </Flex>
                      {notifications && !!notifications.length && (
                        <Badge
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          rounded="50%"
                          variant="solid"
                          colorScheme="red"
                          height="18px"
                          width="18px"
                          position="absolute"
                          right={['5px', '-10px']}
                          top="12px"
                        >
                          {notifications?.length}
                        </Badge>
                      )}
                    </Flex>
                  </Link>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}

        {(can('list-responsibility') ||
          can('list-user') ||
          can('list-role') ||
          can('list-permission') ||
          can('list-companies') ||
          can('list-emails')) && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton mb={[3, 0]}>
                  <Box flex="1" textAlign="left" fontSize="sm" fontWeight="600">
                    Configurações
                  </Box>
                  {isExpanded ? (
                    <FiMinus size="12px" />
                  ) : (
                    <FiPlus size="12px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {can('list-responsibility') && (
                    <Link to="/controle/cargos">
                      <Flex alignItems="center" py={2}>
                        <FiTarget size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Cargos
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-user') && !user.is_subscribed && (
                    <Link to="/controle/usuarios">
                      <Flex alignItems="center" py={2}>
                        <FiUsers size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Usuários
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-role') && (
                    <Link to="/controle/funcoes">
                      <Flex alignItems="center" py={2}>
                        <FiLock size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Funções
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-permission') && (
                    <Link to="/controle/permissoes">
                      <Flex alignItems="center" py={2}>
                        <FiShield size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Permissões
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-emails') && (
                    <Link to="/gerenciar/email">
                      <Flex alignItems="center" py={2}>
                        <FiMail size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          E-mail
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-companies') && (
                    <Link to="/empresas-cliente">
                      <Flex alignItems="center" py={2}>
                        <FaBuilding size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Empresas
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-department') && (
                    <Link to="/gerenciar/departamentos">
                      <Flex alignItems="center" py={2}>
                        <FiGrid size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Departamentos
                        </Text>
                      </Flex>
                    </Link>
                  )}

                  {can('list-dimension-gdp') && (
                    <Link to="/dimensao">
                      <Flex alignItems="center" py={2}>
                        <FiBox size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Dimensões
                        </Text>
                      </Flex>
                    </Link>
                  )}
                  {can('list-signature') && user.is_subscribed && (
                    <Link to="/assinaturas">
                      <Flex alignItems="center" py={2}>
                        <FaFileSignature size={22} />
                        <Text fontSize="md" fontWeight="medium" ml={2}>
                          Licenças
                        </Text>
                      </Flex>
                    </Link>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </Box>
  );
};

export default Menu;
