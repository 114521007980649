import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { FaEye, FaEyeSlash, FaRegAddressCard } from 'react-icons/fa';
import {
  FiMail,
  FiEdit,
  FiPlus,
  FiShield,
  FiXOctagon,
  FiTrash,
  FiMinus,
  FiCheck,
  FiCrosshair,
  FiX,
  FiLock,
  FiHelpCircle,
} from 'react-icons/fi';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import StateManager from 'react-select';

import {
  Box,
  Button,
  Heading,
  Avatar,
  Text,
  Badge,
  Flex,
  Grid,
  useToast,
  FormLabel,
  Switch,
  Tooltip,
  Icon,
  IconButton,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
  Skeleton,
  Textarea,
  ButtonGroup,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { Step } from 'intro.js';
import { debugPort } from 'process';
import queryString from 'query-string';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import ToggleCustom from '~/modules/dashboard/components/ToggleCustom';
import { Department, Member } from '~/modules/management/@types/management';
import ManageEmail from '~/modules/management/components/ManageEmail';
import AlertDialog from '~/shared/components/AlertDialog';
import AvatarCustom from '~/shared/components/AvatarCustom';
import InputChakra from '~/shared/components/InputChakra';
import Select from '~/shared/components/InputChakra/SelectChakra';
import SwitchChakra from '~/shared/components/InputChakra/SwitchChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import ModalManageUsers, {
  UserProps,
} from '~/shared/components/ModalManageUsers';
import {
  UserCardInfo,
  IconButtonUser,
} from '~/shared/components/ModalManageUsers/styles';
import SectionHeader from '~/shared/components/SectionHeader';
import MultiSelect from '~/shared/components/Select/MultiSelect';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import {
  UserSelect,
  DepartmentSelect,
  FormData,
  CorporateEmail,
  getAllEmails,
} from '..';

interface ParamsUrl {
  departmentId?: string;
}

interface SubordinateDepartment {
  id: number;
  name: string;
  company_id: number;
  slug: string;
  description?: string;
  responsible_id?: number;
}

interface RefreshUpdateResult {
  user: Member;
  remove?: boolean;
  success: boolean;
}

interface SelectedMember {
  id: number;
  name: string;
}

const ManageDepartment: React.FC = () => {
  const { departmentId } = useParams<ParamsUrl>();

  const history = useHistory();

  const addToast = useToast();
  const { user: userSession } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formSubordinateRef = useRef<FormHandles>(null);
  const formModalRef = useRef<FormHandles>(null);
  const btnEditRef = useRef(null);
  const cancelRef = useRef(null);
  const toggleRef = useRef(null);

  const {
    isOpen: isOpenAllUsers,
    onOpen: onOpenAllUsers,
    onClose: onCloseAllUsers,
  } = useDisclosure();

  const {
    isOpen: isOpenDestroy,
    onOpen: onOpenDestroy,
    onClose: onCloseDestroy,
  } = useDisclosure();

  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();

  const {
    isOpen: isOpenToggle,
    onOpen: onOpenToggle,
    onClose: onCloseToggle,
  } = useDisclosure();

  const [editing, setEditing] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingToggleDepart, setLoadingToggleDepart] = useState(true);
  const [loadingToggleDepartAlert, setLoadingToggleDepartAlert] = useState(
    false,
  );
  const [loadedInfo, setLoadedInfo] = useState(false);
  const [loadedEmails, setLoadedEmails] = useState(false);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [loadedSubordinates, setLoadedSubordinates] = useState(false);
  const [loadedFormOptions, setLoadedFormOptions] = useState(false);
  const [removedMember, setRemovedMember] = useState<SelectedMember | null>(
    null,
  );

  const [onResponsibility, setOnResponsibility] = useState(false);

  const [users, setUsers] = useState<UserSelect[]>([]);
  const [membersModal, setMembersModal] = useState<UserProps[] | undefined>([]);
  const [department, setDepartment] = useState<Department>({} as Department);
  const [emailDepartment, setEmailDepartment] = useState<CorporateEmail[]>([]);
  const [subordinateDepartments, setSubordinateDepartments] = useState<
    DepartmentSelect[]
  >([]);
  const [corporateEmail, setCorporateEmail] = useState<CorporateEmail[]>([]);
  const [newSubordinateDepartaments, setNewSubordinateDepartments] = useState<
    number[]
  >([]);
  const [allDepartments, setAllDepartments] = useState<DepartmentSelect[]>([]);
  const [toggleSupDepartment, setToggleSupDepartment] = useState(false);
  const [refreshDepartment, setRefreshDepartment] = useState(0);

  /* const [toggleDisableDepartment, setToggleDisableDepartment] = useState(
    () => !!queryParams?.disable || queryParams?.disable === true,
  ); */

  const [toggleDepartment, setToggleDepartment] = useState(false);
  const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  const [enableTourDepartment, setEnableTourDepartment] = useState(false);
  const [stepsDepartment, setStepsDepartment] = useState<Step[]>([
    {
      element: '#container',
      title: 'Informações do departamento',
      intro:
        'Nessa página você acompanha e administra todos os usuários composto na equipe.',
    },
    {
      element: '#editDepartment',
      title: 'Editar dados do departamento',
      intro:
        'Aqui você pode editar os dados do departamento de sua equipe. clique no botão.',
      position: 'left',
    },
    {
      element: '#addFunc',
      title: 'Adicionar funcionario ao departamento',
      intro:
        'Aqui você pode adicionar um funcionario que ainda não tenha departamento. clique no botão.',
      position: 'left',
    },
  ]);

  /* O useMemo pode ser utilizado ao invés do state quando, por exemplo, uma variável
  precisa seu ter seu valor atualizado ao alterar outra variável, neste caso updatedMembers
  depende do state department, apesar da atualização ocorrer com department, apenas utilizamos o valor
  na sua chamada. Mesmo sem ter o valor na última renderização, ao atualizar o state, o useMemo
  também terá o seu valor atualizado */

  const updatedMembers = useMemo((): UserProps[] => {
    // console.log(department);
    if (!department.id || !department.users) return [];

    return department.users.map((us) => {
      return {
        id: us.id,
        name: us.name,
        email: us.email,
        avatar: us?.avatar,
        responsibility_id: us?.responsibility?.id,
        responsibility: us?.responsibility?.name,
        department_id: department.id,
        department: department.name,
        is_active: !!us.is_active,
        // originalUser: true,
      };
    });
  }, [department]);

  useEffect(() => {
    // list_members vai ser true, quando carregarmos a página pela primeira vez somente

    setLoadedEmails(false);

    async function getDepartmentInfo(): Promise<void> {
      setLoadedInfo(false);
      setLoadedMembers(false);
      setLoadedSubordinates(false);

      try {
        const response = await api.get<Department>(
          `department/${departmentId}`,
          {
            params: {
              list_members: !refreshDepartment,
              list_subordinates: !refreshDepartment,
            },
          },
        );

        // limpamos a informação de e-mail que será setada em um estado separado
        setDepartment({ ...response.data, corporate_emails: [] });

        const {
          responsible,
          superior_department_id,
          subordinate_departments,
          corporate_emails: saved_emails,
          disabled_at,
        } = response.data;

        setOnResponsibility(!!responsible);
        setToggleSupDepartment(!!superior_department_id);
        setToggleDepartment(!disabled_at);

        if (!!saved_emails && saved_emails?.length > 0) {
          setEmailDepartment(
            saved_emails?.map((em) => {
              return {
                ...em,
                label: `E-mail`,
                value: em.id,
              };
            }),
          );
        }

        if (subordinate_departments?.length) {
          setSubordinateDepartments(
            subordinate_departments.map((sub) => {
              return {
                ...sub,
                id: sub.id,
                name: sub.name,
                responsible_id: sub.responsible_id,
                slug: sub.slug,
                company_id: sub.company_id,
                value: sub.id,
                label: 'Dept. Sub.',
              };
            }),
          );
        }
      } finally {
        setLoadedInfo(true);
        setLoadingToggleDepart(false);

        if (!refreshDepartment) {
          setLoadedMembers(true);
          setLoadedSubordinates(true);
        }
      }
    }

    getDepartmentInfo();

    getAllEmails()
      .then((data) => {
        setCorporateEmail(
          data.map((email) => {
            return {
              id: email.id,
              email: email.email,
              company_id: email.company_id,
              created_at: email.created_at,
              label: email.email,
              value: email.id,
            };
          }),
        );
      })
      .finally(() => setLoadedEmails(true));
  }, [departmentId, refreshDepartment]);

  useEffect(() => {
    async function loadingOptionsForm(): Promise<void> {
      try {
        const responseUser = await api.get<UserSelect[]>('users/all');

        setUsers(
          responseUser.data.map((us) => {
            return {
              ...us,
              value: us.id,
              label: us.name,
            };
          }),
        );

        const responseDep = await api.get<DepartmentSelect[]>('department', {
          /*  params: {
            list_subordinates: 1,
          }, */
        });

        setAllDepartments(
          responseDep.data
            ?.filter((dep) => Number(dep.id) !== Number(departmentId))
            .map((dep) => {
              return {
                ...dep,
                members: dep.users,
                value: dep.id,
                label: dep.name,
              };
            }),
        );
      } finally {
        // console.log('finalizado');
        setLoadedFormOptions(true);
      }
    }

    loadingOptionsForm();

    // getDepartmentMembers();
  }, [userSession.company_id, departmentId]);

  const handleEditing = useCallback(() => setEditing(!editing), [editing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
  }, []);

  const handleToogleResponsibility = useCallback(() => {
    setOnResponsibility(!onResponsibility);
  }, [onResponsibility]);

  const handleSubmitRemoveMember = async (
    memberObj?: SelectedMember,
  ): Promise<void> => {
    try {
      let member: SelectedMember;

      if (memberObj) {
        member = memberObj;
      } else if (removedMember) {
        member = removedMember;
      } else {
        return;
      }

      setLoadedMembers(false);

      onCloseAlert();

      await api.put(`department-members/${departmentId}`, {
        employee: member.id,
        remove: 1,
      });

      const updatedUsers: Member[] =
        department.users?.filter((us) => us.id !== member?.id) || [];

      setDepartment((state) => ({
        ...state,
        users: updatedUsers,
      }));

      if (!memberObj) setRemovedMember(null);

      addToast({
        position: 'top-right',
        status: 'success',
        title: `Membro removido!`,
        description: `O Membro ${member.name} foi removido do departamento`,
        isClosable: true,
      });
    } catch (err) {
      addToast({
        position: 'top-right',
        status: 'error',
        title: 'Não foi possível remover o membro do departamento',
        description:
          err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        isClosable: true,
      });

      return;
    } finally {
      setLoadedMembers(true);
    }
  };

  const formattedUpdatedUsers = (
    groupUsers: UserProps[],
    remove?: boolean,
  ): Member[] => {
    return groupUsers.map((user) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        avatar: user.avatar,
        responsibility_id: user?.responsibility_id,
        responsibility:
          (!!user.responsibility_id &&
            !!user.responsibility && {
              id: user.responsibility_id,
              name: user.responsibility,
              company_id: Number(userSession.company_id),
            }) ||
          undefined,
        pivot: {
          department_id: Number(departmentId),
          user_id: user.id,
        },
        remove: !!remove,
      };
    });
  };

  const handleRefreshMembers = async (
    addedMembers?: UserProps[],
    removedMembers?: UserProps[],
  ): Promise<void> => {
    // setLoadedMembers(false)
    // Realizar loop para atualizar os funcionários dos dois grupos

    let updatedUser: Member[] = [];

    if (addedMembers) {
      updatedUser = updatedUser.concat(formattedUpdatedUsers(addedMembers));
    }

    if (removedMembers) {
      updatedUser = updatedUser.concat(
        formattedUpdatedUsers(removedMembers, true),
      );
    }

    // console.log(addedMembers, removedMembers);

    if (!updatedUser.length) return;

    setLoadedMembers(false);

    Promise.all(
      updatedUser.map(
        async (user): Promise<RefreshUpdateResult> => {
          const { remove } = user;

          try {
            await api.put(`department-members/${departmentId}`, {
              employee: user.id,
              remove,
            });

            return { remove, user, success: true };
          } catch (err) {
            addToast({
              position: 'top-right',
              status: 'error',
              title: remove
                ? `Não foi possível remover o membro ${user.name} do departamento`
                : `Não foi possível adicionar o membro ${user.name} ao departamento`,
              description: 'Ocorreu um erro, tente novamente.',
              isClosable: true,
            });

            return { remove, user, success: false };
          }
        },
      ),
    ).then((results) => {
      // Atualizamos todos os resultados de uma única vez

      // Valor antes de atualizarmos
      // let updatedUsersSuccess: Member[] = department?.users || [];

      results
        .filter((res) => res.success)
        .forEach((res) => {
          const { remove, user } = res;

          if (remove) {
            setDepartment((state) => ({
              ...state,
              users: state.users?.filter((us) => us.id !== user.id),
            }));
          } else {
            setDepartment((state) => ({
              ...state,
              users: [user, ...(state?.users || [])],
            }));
          }
        });

      // retorna true ou false, que é o resultado de cada promise executada
      setLoadedMembers(true);

      const countSuccessAdded =
        results.filter((res) => res.success && !res.remove).length || 0;

      const countSuccessRemoved =
        results.filter((res) => res.success && res.remove).length || 0;

      if (countSuccessAdded) {
        addToast({
          position: 'top-right',
          status: 'success',
          title: `${countSuccessAdded} membro(s) adicionado(s) ao departamento`,
          isClosable: true,
        });
      }

      if (countSuccessRemoved) {
        addToast({
          position: 'top-right',
          status: 'success',
          title: `${countSuccessRemoved} membro(s) removido(s) do departamento`,
          isClosable: true,
        });
      }
    });
  };

  const handleModalRemoveMember = (member: SelectedMember): void => {
    setRemovedMember(member);
    onOpenAlert();
  };

  async function handleUpdateDepartment(formData: FormData): Promise<void> {
    setLoadingUpdate(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.array()
          .of(Yup.string().required('Se não possui e-mail desmarque a opção'))
          .notRequired(),
        superior_department_id: Yup.string().test(
          'superiorDepartmentValid',
          'O departamento superior não pode ser igual a um departamento subordinado',
          () => {
            return !formData.subordinate_departments?.find(
              (sub) => Number(formData.superior_department_id) === Number(sub),
            );
          },
        ),
        subordinate_departments: Yup.array()
          .of(
            Yup.string().required(
              'Se não possui departamento subordinado desmarque a opção',
            ),
          )
          .notRequired(),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const { data: newDepartment } = await api.put<Department>(
        `department/${departmentId}`,
        formData,
      );

      // Isso para manter os usuários, informação que não é retornada pela rota, já que por meio dela não podemos modificar esses dados
      setDepartment((state) => {
        const {
          name,
          slug,
          responsible,
          responsible_id,
          superior_department_id,
          updated_at,
        } = newDepartment;
        return {
          ...state,
          name,
          slug,
          responsible,
          responsible_id,
          superior_department_id,
          updated_at,
        };
      });

      setEmailDepartment(
        newDepartment.corporate_emails?.map((em) => {
          return {
            ...em,
            label: `E-mail`,
            value: em.id,
          };
        }) || [],
      );

      setSubordinateDepartments(
        newDepartment.subordinate_departments?.map((sub) => {
          return {
            ...sub,
            label: 'Sub. Dept.',
            value: sub.id,
          };
        }) || [],
      );

      // setRefreshDepartment(refreshDepartment + 1);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Departamento Atualizado!',
        description: `As informações do departamento foram atualizadas`,
        status: 'success',
      });

      setEditing(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao atualizar as informações do departamento',
        description: err.response.data?.error || '',
        status: 'error',
      });
    } finally {
      setLoadingUpdate(false);
    }
  }

  async function handleDeleteDepartment(): Promise<void> {
    setLoadingDelete(true);

    try {
      await api.delete(`department/${departmentId}`);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Departamento Apagado!',
        description: `O departamento ${department.name} foi apagado com sucesso!`,
        status: 'success',
      });

      history.goBack();
    } catch (err) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível apagar o departamento',
        description: err.response.data?.error || '',
        status: 'error',
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  function handleAddNewSubordinateOption(): void {
    setSubordinateDepartments((state) => [
      {
        id: 0,
        name: 'Selecione',
        company_id: 1,
        slug: 'selecione',
        value: 0,
        label: 'Selecione',
      },
      ...state,
    ]);
  }

  function handleRemoveNewSubordinateOption(index: number): void {
    setSubordinateDepartments((state) =>
      state.filter((_sub, i) => i !== index),
    );
  }

  const handleAddSubordinateDepartment = (
    subordinate: SubordinateDepartment,
  ): void => {
    const {
      id,
      name,
      company_id,
      description,
      responsible_id,
      slug,
    } = subordinate;

    setLoadedSubordinates(false);

    api
      .put(`department/${id}`, {
        name,
        description,
        responsibile_id: responsible_id,
        superior_department_id: departmentId,
      })
      .then(() => {
        setDepartment((state) => ({
          ...state,
          subordinate_departments: state.subordinate_departments?.length
            ? [
                ...state.subordinate_departments,
                {
                  id,
                  name,
                  description,
                  slug,
                  company_id,
                },
              ]
            : [],
        }));
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          status: 'error',
          title: `Não foi possível adicionar ${name} aos departamentos subordinados`,
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadedSubordinates(true);
      });
  };

  const handleToggleDepartment = useCallback(() => {
    setLoadingToggleDepartAlert(true);
    setToggleDepartment(!toggleDepartment);
    // setLoadedInfo(true);

    api
      .patch(`/department/toggle/${departmentId}`, {
        disable: toggleDepartment ? 1 : 0,
      })
      .then(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: `Departamento ${
            !department.disabled_at ? 'desabilitado' : 'ativado'
          }`,
          description: `Departamento ${
            !department.disabled_at ? 'desabilitado' : 'ativado'
          } com sucesso.`,
          status: 'success',
        });

        setDepartment((state) => ({
          ...state,
          disabled_at: !department.disabled_at ? currentDate : undefined,
        }));
      })

      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: `Erro ao tentar ${
            !department.disabled_at ? 'desabilitar' : 'ativar'
          } o departamento.`,
          description: 'Ocorreu um erro, tente novamente.',
        });

        setToggleDepartment(!!department.disabled_at);
      })

      .finally(() => {
        setLoadingToggleDepartAlert(false);
        setLoadingToggleDepart(false);
        // setLoadedInfo(false);
        onCloseToggle();
      });
  }, [
    departmentId,
    addToast,
    onCloseToggle,
    department.disabled_at,
    toggleDepartment,
    currentDate,
  ]);

  const can = useCan();

  return (
    <>
      <Box id="container">
        <SectionHeader
          title={department?.name ? department.name : ''}
          pagename="Informações do departamento"
          loading={!loadedInfo}
          goBackPage
        >
          <Flex alignItems="center">
            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourDepartment(true);
              }}
              variant="outline"
              colorScheme="blue"
              mr={3}
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
            <Button
              id="editDepartment"
              leftIcon={<FiEdit />}
              colorScheme="blue"
              variant="solid"
              onClick={handleEditing}
              isDisabled={!loadedInfo || !loadedFormOptions}
              isLoading={!loadedInfo || !loadedFormOptions}
              display={editing ? 'none' : 'inline-flex'}
              ref={btnEditRef}
              width="112px"
            >
              Editar
            </Button>

            <Button
              leftIcon={<FiTrash />}
              colorScheme="red"
              variant="outline"
              onClick={onOpenDestroy}
              isDisabled={!loadedInfo}
              display={editing ? 'none' : 'inline-flex'}
              ml={2}
              width="112px"
            >
              Apagar
            </Button>
          </Flex>
        </SectionHeader>

        {can('toggle-status-department') && (
          <Flex justifyContent="flex-end">
            {loadingToggleDepart ? (
              <Skeleton width="237px" height="32px" />
            ) : (
              <Box>
                <ToggleCustom
                  defaultIsChecked={!department.disabled_at}
                  toogleAdjust={() => {
                    onOpenToggle();
                    setLoadingToggleDepart(true);
                    // setToggleDepartment((state) => !state);
                  }}
                  loading={loadingToggleDepart}
                  tittle={`${
                    !department.disabled_at ? 'Desabilitar' : 'Ativar'
                  } departamento`}
                  icon={
                    !department.disabled_at ? (
                      <FaEyeSlash color="#3182ce" />
                    ) : (
                      <FaEye color="#3182ce" />
                    )
                  }
                  id="showDisabledDepartment"
                />
              </Box>
            )}
          </Flex>
        )}

        {/* <Flex justifyContent="flex-end">
          <Box>
            {!department.disabled_at ? (
              <Button
                colorScheme="yellow"
                isLoading={!loadedInfo || loadingDisable}
                onClick={() => onOpenDisable()}
              >
                Desabilitar
              </Button>
            ) : (
              <Button
                colorScheme="green"
                isLoading={!loadedInfo || loadingDisable}
                onClick={() => onOpenEnable()}
              >
                Habilitar
              </Button>
            )}
          </Box>
        </Flex> */}

        <Box width="100%">
          {editing ? (
            <Box pb={4} mb={4} borderBottomWidth={1}>
              <Form
                ref={formRef}
                onSubmit={handleUpdateDepartment}
                initialData={{
                  name: department?.name,
                  superior_department_id: department?.superior_department_id,
                  responsible_id: department?.responsible_id,
                }}
              >
                <Box>
                  <Box p={2}>
                    <InputChakra
                      label="Nome"
                      name="name"
                      type="text"
                      placeholder="Ex: Gr Serviços"
                      autoFocus
                      help="Nome do departamento"
                    />
                  </Box>
                  <Flex
                    flexDirection={['column', 'row']}
                    justifyContent="space-between"
                  >
                    <Box height="90px" flex={1} p={2}>
                      <Flex alignItems="center">
                        <FormLabel
                          htmlFor="responsible"
                          fontWeight={500}
                          mb={1}
                        >
                          Definir um responsável?
                        </FormLabel>
                        <Switch
                          id="responsible"
                          onChange={handleToogleResponsibility}
                          defaultIsChecked={onResponsibility}
                          mb={1}
                          size="sm"
                        />
                        {onResponsibility && (
                          <Tooltip
                            aria-label="Você pode definir quem será o responsável pelo departamento."
                            label="Você pode definir quem será o responsável pelo departamento."
                            zIndex={1}
                            placement="left"
                          >
                            <Box as="span" ml="auto">
                              <Icon name="info-outline" color="gray.500" />
                            </Box>
                          </Tooltip>
                        )}
                      </Flex>
                      {onResponsibility && (
                        <Select
                          name="responsible_id"
                          placeholder="Selecione"
                          options={users}
                        />
                      )}
                    </Box>
                    <Box height="90px" flex={1} p={2}>
                      <Flex alignItems="center">
                        <FormLabel
                          htmlFor="superior_department"
                          mb={1}
                          fontWeight={500}
                        >
                          Possui departamento superior?
                        </FormLabel>
                        <Switch
                          id="superior_department"
                          onChange={() =>
                            setToggleSupDepartment(!toggleSupDepartment)
                          }
                          defaultIsChecked={toggleSupDepartment}
                          size="sm"
                          mb={1}
                        />
                      </Flex>
                      {toggleSupDepartment && (
                        <Select
                          name="superior_department_id"
                          options={allDepartments.filter(
                            (dep) =>
                              !subordinateDepartments.find(
                                (sub) => sub.id === dep.id,
                              ),
                          )}
                          placeholder="Selecione o departamento superior"
                        />
                      )}
                    </Box>
                  </Flex>
                  <Flex
                    flexDirection={['column', 'row']}
                    justifyContent={['start', 'space-between']}
                  >
                    {loadedEmails && !!corporateEmail.length && (
                      <Box p={2} flex={1}>
                        <MultiSelect
                          data={corporateEmail}
                          defaultValue={emailDepartment}
                          name="email"
                          title="E-mail"
                          toggleQuest="Possui e-mail(s)?"
                          placeholder="Selecione o e-mail corporativo"
                        />
                      </Box>
                    )}
                    {!!allDepartments.length && (
                      <Box p={2} flex={1}>
                        <MultiSelect
                          data={allDepartments.filter(
                            (dep) =>
                              !dep.superior_department_id ||
                              (dep.superior_department_id ===
                                Number(departmentId) &&
                                dep.id !== department.superior_department_id),
                          )}
                          defaultValue={subordinateDepartments}
                          name="subordinate_departments"
                          title="Dept. Sub."
                          toggleQuest="Possui departamentos subordinados(s)?"
                          placeholder="Selecione o departamento subordinado"
                        />
                      </Box>
                    )}
                  </Flex>
                </Box>

                <Flex justifyContent="flex-end" mt={[2, 3]}>
                  {!loadingUpdate && (
                    <Button variant="ghost" mr={3} onClick={handleCancel}>
                      Cancelar
                    </Button>
                  )}
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => formRef.current?.submitForm()}
                    isLoading={loadingUpdate}
                    isDisabled={loadingUpdate}
                  >
                    Salvar
                  </Button>
                </Flex>
              </Form>
            </Box>
          ) : (
            <>
              <Box
                d="flex"
                flexDir="row"
                justifyContent={['center', 'left']}
                alignItems="center"
                flexWrap="wrap"
                w="100%"
                pb={2}
                mb={4}
              >
                {!loadedInfo ? (
                  <>
                    <AvatarCustom
                      name=""
                      size="xl"
                      ml="auto"
                      mr={2}
                      borderWidth="1px"
                      borderColor="gray"
                      mx={[0, 2]}
                    />
                    <Box d="flex" flexDir="column" px={[0, 2]} order={[1, 2]}>
                      <Heading as="h5" size="xs" mb={2}>
                        Responsável
                      </Heading>
                      <Skeleton height={6} width="150px" mb="1px" />
                      {/* Quando se coloca um número decimal o valor é entendido como pixel,
                    como inteiro é entendido como rem, ao usar um decimal diferente de 0, o valor em pixel não será
                    exatamente o passado, ex: 18.2 é 18.166 px */}
                      <Skeleton height="18px" width="150px" mb={1} />
                      <Skeleton height="18px" width="60px" />
                    </Box>
                  </>
                ) : (
                  <>
                    <AvatarCustom
                      src={department?.responsible?.avatar}
                      name={department?.responsible?.name}
                      size="xl"
                      ml="auto"
                      mr={2}
                      borderWidth="1px"
                      borderColor="gray"
                      mx={[0, 2]}
                      mb={[3, 0]}
                    />
                    <Box display="flex" flexDir="column" px={[0, 2]}>
                      <Heading as="h5" size="xs" mb={2}>
                        Responsável
                      </Heading>
                      <Heading
                        isTruncated
                        as="h3"
                        size="md"
                        textAlign="left"
                        mb="1px"
                      >
                        {department?.responsible?.name || 'Não definido'}
                      </Heading>
                      {!!department?.responsible && (
                        <>
                          <Text
                            isTruncated
                            color="gray.500"
                            fontSize="xs"
                            mb={1}
                          >
                            {department?.responsible?.email}
                          </Text>

                          <Badge
                            alignSelf="flex-start"
                            fontSize="xs"
                            color="gray.500"
                          >
                            {department?.responsible?.responsibility?.name}
                          </Badge>
                        </>
                      )}

                      {!!department.disabled_at && (
                        <Flex justifyContent="center" alignItems="center">
                          <Text
                            isTruncated
                            color="gray.500"
                            // fontSize="xs"
                            mb={1}
                          >
                            Desabilitado em:
                          </Text>
                          <Badge colorScheme="red" ml="5px">
                            {format(
                              parseISO(department.disabled_at),
                              "dd/MM/yyyy 'às' HH:mm",
                            )}
                          </Badge>
                        </Flex>
                      )}
                    </Box>
                  </>
                )}
              </Box>

              {/* {!!department?.description && (
                <Box maxWidth="350px">
                  <textarea
                    defaultValue={department?.description}
                    readOnly
                    style={{
                      width: '100%',
                      borderRadius: '5px',
                      padding: '0.6rem',
                      backgroundColor: '#fcfcfc',
                    }}
                  />
                </Box>
              )} */}
            </>
          )}
        </Box>
        <Flex flexDir="column" my={8} pt={8}>
          <Flex justifyContent="space-between">
            <Heading as="h4" size="md" color="gray.500" fontWeight={500}>
              <span>Membros do Departamento</span>{' '}
              <span>({(loadedMembers && department?.users?.length) || 0})</span>
            </Heading>

            <Button id="addFunc" leftIcon={<FiPlus />} onClick={onOpenAllUsers}>
              Adicionar
            </Button>
          </Flex>

          {!loadedMembers ? (
            <Box pt={4}>
              <Loading />
            </Box>
          ) : (
            <>
              <Grid
                justifyContent="flex-start"
                templateColumns={[
                  '1fr',
                  'repeat(auto-fill, minmax(220px, 1fr))',
                ]}
                gap="10px"
                pt={4}
                mb={5}
                w="100%"
              >
                {!!department?.users &&
                  !!department?.users.length &&
                  department?.users.map((member) => (
                    <Box
                      key={member.id}
                      display="flex"
                      flexDir="row"
                      alignItems="center"
                      shadow="sm"
                      rounded="sm"
                      pos="relative"
                      p={3}
                    >
                      <AvatarCustom
                        src={member.avatar}
                        name={member.name}
                        ml="auto"
                        mr="auto"
                      />
                      <Box
                        d="flex"
                        flexDir="column"
                        alignItems="flex-start"
                        flex={1}
                        px={2}
                      >
                        <Tooltip
                          hasArrow
                          aria-label={member.name}
                          label={member.name}
                          zIndex={1}
                        >
                          <Heading
                            isTruncated
                            as="h4"
                            size="sm"
                            w={150}
                            textAlign="left"
                            pr={10}
                          >
                            {member.name}
                          </Heading>
                        </Tooltip>

                        <IconButton
                          aria-label="Remover usuário"
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          icon={<FiXOctagon />}
                          pos="absolute"
                          top={1}
                          right={1}
                          onClick={() =>
                            handleModalRemoveMember({
                              id: member.id,
                              name: member.name,
                            })
                          }
                          _focus={{
                            boxShadow: 'none',
                          }}
                        />

                        <Tooltip
                          hasArrow
                          aria-label={member.email}
                          label={member.email}
                          zIndex={1}
                        >
                          <Text
                            isTruncated
                            color="gray.500"
                            fontSize="xs"
                            w={150}
                            pr={2}
                          >
                            {member.email}
                          </Text>
                        </Tooltip>

                        <Heading
                          d="flex"
                          alignItems="center"
                          mt={1}
                          justifyContent="center"
                          fontSize="xs"
                          color="gray.500"
                        >
                          {member?.responsibility ? (
                            <>
                              <FaRegAddressCard size={16} />
                              <Text ml={1}>{member.responsibility.name}</Text>
                            </>
                          ) : (
                            <Text color="red.500">Sem cargo</Text>
                          )}
                        </Heading>
                      </Box>
                    </Box>
                  ))}
              </Grid>
            </>
          )}
        </Flex>

        {/* Para recarregar as informações do novo departamento não seria
        necessário acessar a rota novamente, apenas alterar o departmentId que
        está no array de dependência do userEffect, porém dessa forma o botão de
        voltar, não representaria todo o caminho realizado navegando entre os
        departamentos, voltaria sempre para a página de listagem de todos os
        departamentos */}
        {/* <Box>
          <Heading as="h4" size="md" color="gray.500" fontWeight={500}>
            <span>Departamentos Subordinados</span>{' '}
            <span>
              (
              {(loadedInfo && department?.subordinate_departments?.length) || 0}
              )
            </span>
          </Heading>
          {!loadedSubordinates ? (
            <Box pt={4}>
              <Loading />
            </Box>
          ) : (
            <Box pt={4}>
              <Form ref={formSubordinateRef} onSubmit={handleUpdateDepartment}>
                <Flex
                  flexDir={
                    subordinateDepartments.filter((sub) => !sub.id).length
                      ? 'column'
                      : 'row'
                  }
                >
                  <Flex wrap="wrap">
                    <ButtonGroup
                      size="sm"
                      isAttached
                      variant="outline"
                      height="full"
                      m={2}
                    >
                      <Button mr="-px">Novo</Button>
                      <IconButton
                        aria-label="Adicionar Subordinados"
                        icon={<FiPlus />}
                        colorScheme="green"
                        borderColor="#E2E8F0"
                        onClick={handleAddNewSubordinateOption}
                      />
                    </ButtonGroup>
                    {!!subordinateDepartments.length &&
                      subordinateDepartments
                        .filter((sub) => !sub.id)
                        .map((sub, i) => {
                          const index = i;
                          return (
                            <ButtonGroup
                              key={`${sub.id} - ${index}`}
                              size="sm"
                              isAttached
                              variant="outline"
                              height="full"
                              m={2}
                            >
                              <select
                                name="superior_department_id"
                                // onChange={(e) => handle}
                                style={{
                                  border: '1px solid',
                                  borderRadius: '5px',
                                  borderColor: '#E2E8F0',
                                  height: '2rem',
                                  paddingRight: '0.75rem',
                                  paddingLeft: '0.75rem',
                                }}
                              >
                                <option value="">{sub.name}</option>
                                {allDepartments
                                  .filter(
                                    (dep) =>
                                      !subordinateDepartments.find(
                                        (subordinate) =>
                                          subordinate.id === dep.id,
                                      ) && !dep.superior_department_id,
                                  )
                                  ?.map((dep) => (
                                    <option value={dep.id}>{dep.name}</option>
                                  ))}
                              </select>

                              <Flex>
                                <IconButton
                                  aria-label="Adicionar Subordinado ao Departamento"
                                  icon={<FiCheck />}
                                  colorScheme="green"
                                  borderColor="#E2E8F0"
                                  onClick={() =>
                                    handleAddSubordinateDepartment(sub)
                                  }
                                />
                                <IconButton
                                  aria-label="Cancelar"
                                  icon={<FiX />}
                                  colorScheme="gray"
                                  borderColor="#E2E8F0"
                                  onClick={() =>
                                    handleRemoveNewSubordinateOption(index)
                                  }
                                />
                              </Flex>
                            </ButtonGroup>
                          );
                        })}
                  </Flex>
                  <Flex wrap="wrap">
                    {!!subordinateDepartments.length &&
                      subordinateDepartments
                        .filter((sub) => !!sub.id)
                        .map((sub, i) => {
                          const index = i;
                          return (
                            <ButtonGroup
                              key={`${sub.id}-${index}`}
                              size="sm"
                              isAttached
                              variant="outline"
                              // display={!sub.id ? 'block' : 'inline-block'}
                              m={2}
                            >
                              <Button
                                mr="-px"
                                onClick={() => {
                                  if (sub.id)
                                    history.push(
                                      `/gerenciar/departamento/${sub.id}`,
                                    );
                                }}
                              >
                                <Flex flexDir="column">
                                  <Text>{sub.name}</Text>
                                </Flex>
                              </Button>

                              <IconButton
                                aria-label="Remover Subordinado do Departamento"
                                icon={<FiMinus />}
                                colorScheme="red"
                                borderColor="#E2E8F0"
                              />
                            </ButtonGroup>
                          );
                        })}
                  </Flex>
                </Flex>
              </Form>
            </Box>
          )}
        </Box> */}
      </Box>
      {!!department && loadedMembers && (
        <ModalManageUsers
          mode="department"
          title="Funcionários do Departamento"
          initialAdded={updatedMembers}
          isOpen={isOpenAllUsers}
          onClose={(updatedUsers) => {
            onCloseAllUsers();
            handleRefreshMembers(updatedUsers.added, updatedUsers.removed);
          }}
        />
      )}

      <AlertDialog
        title={`Remover ${removedMember?.name} de ${department?.name}?`}
        description="O membro será removido do departamento."
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        onSubmit={() => handleSubmitRemoveMember()}
      />
      <AlertDialog
        title="Apagar o Departamento?"
        description="O departamento será apagado e todos os membros incluindo o responsável, serão removidos dele"
        isOpen={isOpenDestroy}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDestroy}
        onSubmit={() => handleDeleteDepartment()}
        isLoading={loadingDelete}
      />
      <AlertDialog
        title={`${
          !department.disabled_at ? 'Desabilitar' : 'Ativar'
        } departamento.`}
        description={`Tem certeza que desejar ${
          !department.disabled_at ? 'desabilitar' : 'ativar'
        } esse departamento?`}
        isOpen={isOpenToggle}
        leastDestructiveRef={toggleRef}
        onClose={() => {
          onCloseToggle();
          setLoadingToggleDepart(false);
        }}
        // onCancel={}
        onSubmit={handleToggleDepartment}
        isLoading={loadingToggleDepartAlert}
        VariantColor="yellow"
        submitButtonColor="yellow.500"
      />
      <StepsIntro
        enabled={enableTourDepartment}
        onExit={() => setEnableTourDepartment(false)}
        steps={stepsDepartment}
      />
    </>
  );
};

export default ManageDepartment;
