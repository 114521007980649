import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FiCheckCircle,
  FiPauseCircle,
  FiPlayCircle,
  FiClipboard,
  FiPaperclip,
  FiMail,
  FiBell,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { useTimer } from '~/hooks/timer';

import { Container, TimerCircle, Timer, ReminderNote } from './styles';

interface Props {
  loadingAction: boolean;
  activity_id: number;
  time_lapsed: string;
  activity_duration: string;
  finish(timeIsOver: boolean): void;
  startActivity(): void;
  pausedActivity(time: string): void;
  viewPlanningInfo?(): void;
  viewSendEmail?(): void;
}

const MyTimer: React.FC<Props> = ({
  loadingAction,
  activity_id,
  time_lapsed,
  activity_duration,
  finish,
  startActivity,
  pausedActivity,
  viewPlanningInfo,
  viewSendEmail,
}) => {
  const { seconds, minutes, hours, isRunning, start, pause } = useTimer();

  const formattedTime = useMemo(() => {
    const tH = hours < 10 ? `0${hours}` : hours;
    const tM = minutes < 10 ? `0${minutes}` : minutes;
    const tS = seconds < 10 ? `0${seconds}` : seconds;

    return `${tH}:${tM}:${tS}`;
  }, [hours, minutes, seconds]);

  const duration = new Date(`1970-01-01 ${activity_duration}`);

  function handleTimeIsOver(): boolean {
    if (
      hours > duration.getHours() ||
      (hours === duration.getHours() && minutes > duration.getMinutes()) ||
      (hours === duration.getHours() &&
        minutes === duration.getMinutes() &&
        seconds >= duration.getSeconds())
    ) {
      return true;
    }

    return false;
  }

  const timeIsOver = handleTimeIsOver();

  useEffect(() => {
    start(activity_id, time_lapsed);
  }, [activity_id, start, time_lapsed]);

  const handlePauseTime = useCallback(() => {
    pause();
    pausedActivity(formattedTime);
  }, [pause, pausedActivity, formattedTime]);

  const handleStartTime = useCallback(() => {
    startActivity();
    start(activity_id, formattedTime);
  }, [start, activity_id, startActivity, formattedTime]);

  const handleFinish = useCallback(() => {
    finish(timeIsOver);
  }, [finish, timeIsOver]);
  return (
    <Container timerOver={timeIsOver}>
      <ReminderNote>
        <FiBell size={14} />
        <Text>
          Atualize a página após um período de ausência do site para exibir o
          tempo decorrido real
        </Text>
      </ReminderNote>

      <Timer timerOver={timeIsOver}>
        <small>Tempo decorrido</small>
        <strong>
          <span>{hours < 10 ? `0${hours}` : hours}</span>:
          <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </strong>
        <small>hh:mm:ss</small>

        <TimerCircle timerOver={timeIsOver} />
      </Timer>

      {timeIsOver && (
        <Box marginY={2} textAlign="center">
          <Text fontWeight={700} fontSize="md">
            Você ultrapassou o tempo disponível!
          </Text>
          <Text fontSize="sm">Finalize a atividade assim que possível.</Text>
        </Box>
      )}

      <Box>
        <Box display="flex" justifyContent="space-between" pt={3}>
          {isRunning ? (
            <Button
              leftIcon={<FiPauseCircle />}
              disabled={loadingAction}
              variant="ghost"
              colorScheme="gray"
              bg="gray.50"
              onClick={handlePauseTime}
            >
              Pausar
            </Button>
          ) : (
            <Button
              leftIcon={<FiPlayCircle />}
              disabled={loadingAction}
              variant="ghost"
              colorScheme="gray"
              bg="gray.50"
              onClick={handleStartTime}
            >
              Continuar
            </Button>
          )}

          <Button
            leftIcon={<FiCheckCircle />}
            variant="solid"
            colorScheme={timeIsOver ? 'red' : 'green'}
            onClick={handleFinish}
            ml={2}
            disabled={loadingAction}
          >
            Finalizar
          </Button>
        </Box>
        {isRunning && (
          <>
            <Box w="full" mt={2}>
              {!!viewPlanningInfo && (
                <Button
                  leftIcon={<FiPaperclip />}
                  variant="outline"
                  colorScheme="blue"
                  w="full"
                  onClick={() => viewPlanningInfo()}
                >
                  Anexar arquivos
                </Button>
              )}
            </Box>
            <Box w="full" mt={2}>
              {!!viewSendEmail && (
                <Button
                  leftIcon={<FiMail />}
                  variant="outline"
                  colorScheme="yellow"
                  w="full"
                  onClick={() => viewSendEmail()}
                >
                  Enviar e-mail
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default MyTimer;
