import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  FiFileText,
  FiCalendar,
  FiUser,
  FiClock,
  FiLifeBuoy,
  FiBookOpen,
  FiRotateCcw,
  FiPaperclip,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Grid,
  Skeleton,
  useToast,
  useDisclosure,
  Badge,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { mutate } from 'swr';

import { useTimer } from '~/hooks/timer';
import {
  ActivityProps as Activity,
  Pop,
  ActivityControl,
} from '~/modules/activityExecution/@types/activity';
import AlertDialog from '~/shared/components/AlertDialog';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import api from '~/shared/services/api';

import MyTimer from '../MyTimer';
import {
  Container,
  Content,
  Description,
  Information,
  TimeInformation,
  PostIt,
} from './styles';

interface Props {
  planningId: number;
  employee: number;
  isSessionUser?: boolean;
  canAttachFiles?: boolean;
  canSendEmail?: boolean;
  paramsPushLink?: string;
  company: number;
  companyHasFolderStructure?: boolean;
  warningFinishActivity?: string;
  finish(): void;
  updateActivity(
    status: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado',
    status_id: number,
  ): void;
  pop?(data: Pop): void;
  modalDevolution?(id: number, observation?: string): void;
}

const RunningActivity: React.FC<Props> = ({
  planningId,
  employee,
  isSessionUser = false,
  canAttachFiles = false,
  canSendEmail = false,
  companyHasFolderStructure = false,
  warningFinishActivity,
  company,
  finish,
  paramsPushLink,
  updateActivity,
  pop,
  modalDevolution,
}) => {
  const cancelRef = useRef(null);
  const { reset } = useTimer();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const addToast = useToast();
  const [activityIsLate, setActivityIsLate] = useState(false);
  const [activity, setActivity] = useState<Activity>({} as Activity);
  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);

  /* No caso do canAttachFiles e canSendEmail se o valor for false, e testarmos a condição
  o número 0 é impresso na tela, por isso precisamos sempre utilizar '!!' para realizar
  a conversão pra boolean, curiosamente o mesmo não acontece quando o valor 1 (true) */

  useEffect(() => {
    if (!planningId) return;

    async function loadActivity(): Promise<void> {
      try {
        const response = await api.get<Activity>('plannings/running', {
          params: {
            employee,
          },
        });

        const responseControl = await api.get<ActivityControl>(
          `/activity/control/${planningId}`,
          {
            params: {
              company,
            },
          },
        );

        if (!response.data) return;

        const { data } = response;
        const activityControl = responseControl.data;

        if (!data.start_date) return;

        const dateStart = data.start_real_date
          ? new Date(data?.start_real_date)
          : new Date();
        const dateDuration = new Date(`${data.start_date} ${data.duration}`);
        dateStart.setHours(dateStart.getHours() + dateDuration.getHours());
        dateStart.setMinutes(
          dateStart.getMinutes() + dateDuration.getMinutes(),
        );

        setActivity({
          ...data,
          formatted_start_date:
            data.start_date && format(parseISO(data.start_date), 'dd/MM/yyyy'),
          formatted_start_time:
            data.start_date &&
            format(parseISO(`${data.start_date} ${data.start_time}`), 'HH:mm'),
          formatted_duration:
            data.start_date &&
            format(
              parseISO(`${data.start_date} ${data.duration}`),
              "HH'h' mm'min'",
            ),
          blocked: !!data.blocked,
          formatted_estimated_time: format(
            dateStart,
            "dd ' de ' MMMM, yyyy 'às' HH:mm'h'",
            {
              locale: ptBR,
            },
          ),
          fatal_date: data.fatal_date
            ? format(
                parseISO(data.fatal_date),
                "dd ' de ' MMMM, yyyy 'às' HH:mm'h'",
                {
                  locale: ptBR,
                },
              )
            : undefined,
          formatted_start_and_date_time: `${format(
            parseISO(`${data.start_date} ${data.start_time}`),
            "dd/MM/yyyy 'às' HH:mm",
          )} - ${format(
            parseISO(`${data.end_date} ${data.end_time}`),
            "dd/MM/yyyy 'às' HH:mm",
          )}`,
          formatted_start_date_time: format(
            parseISO(`${data.start_date} ${data.start_time}`),
            "dd/MM/yyyy 'às' HH:mm",
          ),
          formatted_end_date_time: format(
            parseISO(`${data.end_date} ${data.end_time}`),
            "dd/MM/yyyy 'às' HH:mm",
          ),
          time_lapsed: activityControl.time_lapsed || data.time_lapsed,
        });
      } catch (err) {
        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          title: 'Erro ao carregar atividade!',
          description: 'Recarregue a página e tente novamente.',
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    loadActivity();
  }, [addToast, employee, company, planningId]);

  function handleWarningFinishActivity(): string {
    let msg = `Está pronto para finalizar a atividade: ${activity.name}`;

    if (warningFinishActivity) {
      msg += `<br/><br/><b>${warningFinishActivity}</b>`;
    }

    return msg;
  }

  function handlePushPlanningInfo(): void {
    if (!companyHasFolderStructure) {
      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'warning',
        title: 'Não é possível anexar ou verificar arquivos',
        description: 'A empresa não possui a estrutura de pastas no drive',
      });
      return;
    }

    history.push({
      pathname: `/planejamento/${planningId}/informacoes`,
      search: paramsPushLink,
    });
  }

  function handlePushSendEmail(): void {
    history.push({
      pathname: `/planejamento/${planningId}/enviar-email`,
      search: paramsPushLink,
    });
  }

  /* Nesse caso não recomendado utilzar o axios.cancel pois, ao iniciar uma atividade
  e pausar logo em seguida, por exemplo, causando erro por não finalizar a primeira requisição
  não podemos cancelá-la porque precisamos ter o status iniciado e depois pausado */

  const pausedActivity = useCallback(
    async (time: string) => {
      if (loading) return;

      setLoadingAction(true);

      const dateTime = new Date();
      dateTime.setSeconds(dateTime.getSeconds() - 1);

      const last_date_time = format(dateTime, 'yyyy-MM-dd HH:mm:ss');

      const data = {
        employee,
        planning_id: activity.id,
        status_id: activity.situation_id,
        time_lapsed: time,
        last_date_time,
      };

      try {
        await api.post('activity/execution', { ...data });

        setActivity((state) => ({
          ...state,
          situation_id: 17,
          situation: 'Pausado',
        }));

        updateActivity('Pausado', 17);

        addToast({
          title: 'Atividade atualizada!',
          description: `O status da atividade foi alterado para PAUSADO.`,
          position: 'top-right',
          isClosable: true,
          status: 'info',
        });
      } catch (err) {
        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          title: 'Erro ao pausar atividade!',
          description: 'Não foi possível pausar a atividade, tente novamente.',
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } finally {
        setLoadingAction(false);
      }
    },
    [
      loading,
      activity.id,
      activity.situation_id,
      employee,
      addToast,
      updateActivity,
    ],
  );

  const executeActivity = useCallback(async () => {
    if (loading) return;

    setLoadingAction(true);

    const last_date_time = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const data = {
      employee,
      planning_id: activity.id,
      status_id: activity.situation_id,
      last_date_time,
    };

    try {
      await api.post('activity/execution', { ...data });

      setActivity((state) => ({
        ...state,
        situation_id: 15,
        situation: 'Iniciado',
      }));

      updateActivity('Iniciado', 15);

      addToast({
        title: 'Atividade atualizada!',
        description: `O status da atividade foi alterado para INICIADO.`,
        position: 'top-right',
        isClosable: true,
        status: 'info',
      });
    } catch (err) {
      if (err.response.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      addToast({
        title: 'Erro ao executar atividade!',
        description: 'Não foi possível executar a atividade, tente novamente.',
        position: 'top-right',
        isClosable: true,
        status: 'error',
      });
    } finally {
      setLoadingAction(false);
    }
  }, [
    loading,
    activity.id,
    activity.situation_id,
    employee,
    addToast,
    updateActivity,
  ]);

  const handleFinishedActivity = useCallback(async () => {
    if (isSessionUser) {
      reset();
    }

    onClose();

    try {
      const data = {
        planning_id: activity.id,
        employee,
      };

      await api.post('activity/finish', { ...data });

      if (isSessionUser) {
        mutate(`/allocation/personal/${employee}`);
      }

      finish();

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atividade finalizada!',
        description: `A atividade '${activity.name}' foi finalizada com sucesso.`,
        status: 'success',
      });
    } catch (err) {
      if (err.response.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao finalizar atividade!',
        description: 'Não foi possível finalizar a atividade, tente novamente.',
        status: 'error',
      });
    }
  }, [reset, addToast, employee, isSessionUser, activity, onClose, finish]);

  const handlePopOpen = useCallback(() => {
    if (pop) {
      pop({
        name: activity.name,
        description: activity?.activity_description || '',
        pop: activity?.activity_pop || '',
        pop_files: activity?.pop_files || [],
      });
    }
  }, [pop, activity]);

  const handleDevolutionModal = useCallback(() => {
    if (!modalDevolution) return;

    modalDevolution(activity.id, activity?.observation);
  }, [modalDevolution, activity]);

  const handleFinishOpen = (timeIsOver: boolean): void => {
    setActivityIsLate(timeIsOver);
    onOpen();
  };

  return (
    <Container
      width="full"
      minHeight="390px"
      rounded="sm"
      borderWidth={1}
      borderColor="gray.50"
      display="flex"
      mb="60px"
      flexWrap="wrap"
      backgroundImage={[
        'linear-gradient(to right, #f6f7ff00, #fbfbff)',
        'linear-gradient(190deg, #f6f7ff00, #fbfbff)',
      ]}
      position="relative"
      p="30px"
    >
      {loading ? (
        <Box display="flex" flexDirection={['column', 'row']} width="full">
          <Flex flexDirection="column" maxWidth="650px" width="full">
            <Box>
              <Skeleton height="23px" width="100%" mb="1rem" />
              <Skeleton height="54px" width="full" />
              <Skeleton height="75px" width="full" mb="20x" my="10px" />
            </Box>
            <Flex
              height="full"
              flexDirection="column"
              justifyContent="center"
              my={4}
            >
              <Grid
                gap="10px"
                gridTemplateColumns={[
                  'repeat(auto-fit, minmax(200px, 1fr))',
                  'repeat(auto-fit, minmax(300px, 1fr))',
                ]}
              >
                <Skeleton height="34px" width="auto" />
                <Skeleton height="34px" width="auto" />
                <Skeleton height="34px" width="auto" />
                <Skeleton height="34px" width="auto" />
                <Skeleton height="34px" width="auto" />
              </Grid>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            flex={1}
            justifyContent="center"
            alignItems="center"
            mt={[3, 0]}
          >
            <Skeleton height="75px" width="290px" mb="10px" />

            <Skeleton height="230px" width="230px" mb="10px" rounded="100%" />

            <Box>
              <Flex flexDirection="row">
                <Skeleton height="40px" width="90px" mr="5px" />
                <Skeleton height="40px" width="90px" ml="5px" />
              </Flex>
              {!!canAttachFiles && !!paramsPushLink && (
                <Skeleton height="40px" width="full" mt={2} />
              )}
              {isSessionUser && !!canSendEmail && !!paramsPushLink && (
                <Skeleton height="40px" width="full" mt={2} />
              )}
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          <Content>
            <span>
              <Flex id="attendance_activity">
                <Badge colorScheme="blue" mr={1} title="Cód. Atendimento">
                  {activity.attendance_id}
                </Badge>
                <h3>{activity.name}</h3>
              </Flex>
              <Flex>
                {pop && activity.activity_pop && (
                  <Tooltip label="POP da atividade">
                    <button type="button" onClick={handlePopOpen}>
                      <FiBookOpen size={20} />
                      {/* <Text>POP</Text> */}
                    </button>
                  </Tooltip>
                )}

                {!!paramsPushLink && (
                  <>
                    {/* Foi necessário realizar uma alternância entre as condições para o eslint permitir a compilação */}
                    {((!!activity.count_files_attendance &&
                      !!activity.predecessors) ||
                      !!activity.count_files) && (
                      <Tooltip label="Anexos">
                        <button type="button" onClick={handlePushPlanningInfo}>
                          <FiPaperclip size={20} />
                        </button>
                      </Tooltip>
                    )}
                  </>
                )}

                {modalDevolution && activity.observation && (
                  <button type="button" onClick={handleDevolutionModal}>
                    <FiRotateCcw size={20} />
                    {/*  <Text>DEVOL.</Text> */}
                  </button>
                )}
              </Flex>
              {/*  <PostIt>
                <Text fontSize="12px" fontWeight={600} ml="5px">
                  Recarregue a página se você esteve fora por um período para
                  atualizar o contador
                </Text>
              </PostIt> */}
              {activity.description && (
                <Description>
                  <strong>Descrição:</strong>
                  <textarea
                    disabled
                    name="obs"
                    id="obsId"
                    cols={20}
                    rows={3}
                    defaultValue={activity.description}
                  />
                </Description>
              )}
            </span>
            <Flex
              height="full"
              flexDirection="column"
              justifyContent="center"
              my={4}
            >
              <div id="infoExecution">
                {activity.demand && (
                  <Information>
                    <FiFileText size={20} />
                    <div>
                      <strong>{activity.demand}</strong>
                      <small>Demanda</small>
                    </div>
                  </Information>
                )}
                {activity.company && (
                  <Information>
                    <FiUser size={20} />
                    <div>
                      <strong>{activity.company}</strong>
                      <small>Cliente</small>
                    </div>
                  </Information>
                )}
                {activity.formatted_estimated_time && (
                  <Information>
                    <FiCalendar size={20} />
                    <div>
                      <strong>{activity.formatted_estimated_time}</strong>
                      <small>Entrega prevista</small>
                    </div>
                  </Information>
                )}
                {activity.formatted_start_date_time &&
                  activity.formatted_end_date_time && (
                    <TimeInformation>
                      <Information className="planned-date-time">
                        <FiClock size={20} />
                        <div>
                          <Flex mb="2px">
                            <span>De</span>
                            <strong>
                              {activity.formatted_start_date_time}
                            </strong>
                          </Flex>
                          <Flex>
                            <span>Até</span>
                            <strong>{activity.formatted_end_date_time}</strong>
                          </Flex>
                        </div>
                      </Information>
                      <small className="label">
                        Horário de execução (planejado)
                      </small>
                    </TimeInformation>
                  )}
                {activity.formatted_duration && (
                  <Information>
                    <FiLifeBuoy size={20} />
                    <div>
                      <strong>{activity.formatted_duration}</strong>
                      <small>Tempo para execução</small>
                    </div>
                  </Information>
                )}
                {activity.fatal_date && (
                  <Information>
                    <FiCalendar size={20} />
                    <div>
                      <strong>{activity.fatal_date}</strong>
                      <small>Prazo máximo para entrega</small>
                    </div>
                  </Information>
                )}
                {activity.competence && (
                  <Information>
                    <FiCalendar size={20} />
                    <div>
                      <strong>{activity.competence}</strong>
                      <small>Competência</small>
                    </div>
                  </Information>
                )}
              </div>
            </Flex>
          </Content>
          {/* O gerente poderá clicar no botão anexar atividades para verificar os anexos,
          porém não tem sentido ele acessar a página de enviar e-mail da atividade */}
          {activity.time_lapsed && (
            <MyTimer
              finish={(timeIsOver) => handleFinishOpen(timeIsOver)}
              loadingAction={loadingAction}
              startActivity={executeActivity}
              pausedActivity={(time) => pausedActivity(time)}
              activity_id={activity.id}
              time_lapsed={activity.time_lapsed}
              activity_duration={activity.duration}
              viewPlanningInfo={
                canAttachFiles && paramsPushLink
                  ? handlePushPlanningInfo
                  : undefined
              }
              viewSendEmail={
                isSessionUser && canSendEmail && paramsPushLink
                  ? handlePushSendEmail
                  : undefined
              }
            />
          )}
        </>
      )}
      <AlertDialog
        title="Finalizar Atividade?"
        description={handleWarningFinishActivity()}
        htmlContent
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        onSubmit={handleFinishedActivity}
        colorScheme={activityIsLate ? 'red' : 'green'}
        submitButtonColor={activityIsLate ? 'red.500' : 'green.500'}
      />
    </Container>
  );
};

export default RunningActivity;
