import React, { createContext, useCallback, useState, useContext } from 'react';

import { isBefore } from 'date-fns';

import api from '~/shared/services/api';

interface User {
  avatar: string;
  avatar_url: string;
  cover: string;
  cover_url: string;
  created_at: string;
  email: string;
  id: string;
  name: string;
  updated_at: string;
  responsibility_id?: string;
  responsibility?: string;
  department_id?: string;
  department?: string;
  permissions: string[] | null;
  company_id?: number;
  first_access?: boolean;
  is_subscribed?: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  is_subscribed: boolean;
  company_id?: number;
  googleId?: string;
  imageUrl?: string;
}

interface AuthContextData {
  user: User;
  completedTourBSC: boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(updateData: Partial<User>): Promise<void>;
  finishTourBSC(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [completedTourBSC, setCompletedTourBSC] = useState(
    localStorage.getItem('tourBsc') === 'true',
  );

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@MyIPAC:token');
    const expires_token = localStorage.getItem('@MyIPAC:expires_token');
    const user = localStorage.getItem('@MyIPAC:user');

    if (!expires_token) {
      return {} as AuthState;
    }

    const unexpiredToken = isBefore(
      new Date(),
      new Date(JSON.parse(expires_token)),
    );

    if (token && user && unexpiredToken) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      const parseUser = JSON.parse(user);
      api.defaults.headers.company = parseUser.company_id;

      return { token, user: JSON.parse(user) };
    }

    if (!unexpiredToken) {
      localStorage.removeItem('@MyIPAC:token');
      localStorage.removeItem('@MyIPAC:expires_token');
      localStorage.removeItem('@MyIPAC:user');
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({
      email,
      password,
      company_id,
      is_subscribed,
      googleId,
      imageUrl,
    }) => {
      const response = await api.post('sessions', {
        email,
        password,
        company_id,
        googleId,
        imageUrl,
      });

      const {
        token,
        expires_in,
        user,
        responsibility,
        permissions,
        department,
      } = response.data;

      const formattedUser = {
        ...user,
        avatar: user.avatar ? user.avatar : user.google_image,
        responsibility: responsibility?.name,
        responsibility_id: responsibility?.id,
        department_id: department?.id,
        department: department?.name,
        company_id: responsibility?.company_id,
        permissions,
        is_subscribed,
      };

      const expires_token = expires_in;

      localStorage.setItem('@MyIPAC:token', token);
      localStorage.setItem(
        '@MyIPAC:expires_token',
        JSON.stringify(expires_token),
      );

      /** *** TESTE ***** */
      if (!localStorage.getItem('tourBsc')) {
        localStorage.setItem('tourBsc', 'false');
      }

      localStorage.setItem('@MyIPAC:user', JSON.stringify(formattedUser));

      /* Os seguintes cabeçalhos geram preflight por não se encaixarem como requisição simples. Agrega-se a isso o fato
      de utilizar o header Content-Type: application/json, apesar de que não é necessário para GET requests */

      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers.company = company_id;

      setData({ token, user: formattedUser });
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@MyIPAC:token');
    localStorage.removeItem('@MyIPAC:expires_token');
    localStorage.removeItem('@MyIPAC:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    async (updateData: User) => {
      const userUpdate = {
        ...data.user,
        ...updateData,
      };

      localStorage.setItem('@MyIPAC:user', JSON.stringify(userUpdate));

      setData({
        token: data.token,
        user: userUpdate,
      });
    },
    [setData, data],
  );

  const finishTourBSC = useCallback(() => {
    setCompletedTourBSC(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        finishTourBSC,
        completedTourBSC,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
